@import "~org/variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.storylss {
    display: flex;
    flex-direction: column;
    margin: 0 rem(-15px);

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        padding: 0 rem(25px);
        margin: 0;
    }

    @include media-breakpoint-up(llg) {
        padding: 0;
    }
}

.storylss-column {
    overflow: hidden;

    &:nth-child(2) {
        margin: rem(20px) 0;

        @include media-breakpoint-up(lg) {
            margin: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        width: 50%;
    }

    &.left {
        @include media-breakpoint-up(lg) {
            order: 1;
        }

        &.LeftOnTop {
            @include media-breakpoint-down(md) {
                order: 1;
            }
        }
    }

    &.right.LeftOnBottom {
        @include media-breakpoint-down(md) {
            order: 1;
        }
    }
}

.storylss-item {
    img,
    video {
        aspect-ratio: 9 / 8; // stylelint-disable-line
        object-fit: cover;
    }
}

.storylss-items {
    display: flex;

    img,
    video {
        aspect-ratio: 4 / 3; // stylelint-disable-line
        object-fit: cover;
    }

    .storylss-component {
        width: 50%;

        &:nth-child(1) {
            @include media-breakpoint-down(md) {
                padding-right: rem(8px);
            }
        }

        &:nth-child(2) {
            @include media-breakpoint-down(md) {
                padding-left: rem(8px);
            }
        }

        @include media-breakpoint-up(lg) {
            padding-left: rem(20px);
        }

        &.left {
            @include media-breakpoint-up(lg) {
                padding-left: 0;
                padding-right: rem(20px);
            }
        }
    }
}

.storylss-component {
    height: 100%;

    .image-container {
        position: relative;

        &.link {
            margin-bottom: rem(48px);

            .small-standalone-link {
                height: calc(100% + 48px);
            }
        }
    }

    img {
        width: 100%;
        height: 100%;
    }

    .small-standalone-link {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        align-items: flex-end;
        padding-left: rem(12px);
        line-height: rem(16px);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        .icon {
            stroke: none;
            flex-shrink: 0;
            margin-top: rem(-4px);
            margin-bottom: rem(-4px);
        }
    }
}

.storylss-content {
    text-align: center;
    margin-top: rem(32px);
    padding: 0 rem(12px);

    @include media-breakpoint-up(lg) {
        margin-left: rem(20px);
        margin-top: rem(60px);
    }

    &.left {
        @include media-breakpoint-up(lg) {
            margin-left: 0;
            margin-right: rem(20px);
        }
    }

    .body-regular {
        margin-bottom: rem(12px);

        p {
            margin: 0;
        }
    }
}
